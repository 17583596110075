import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import PrimeVue from 'primevue/config';

const OPTIONS_GAME = document.getElementById('app').getAttribute('data-game')

if( !OPTIONS_GAME ){
  throw Error("There is not data-options attribute.")
}

createApp(App,{
  game:OPTIONS_GAME
})
.use(store)
.use(PrimeVue)
.mount('#app')
