import { createStore } from 'vuex'

export default createStore({
  state: {
    settings:{
      "pexeso1":{
        "name":"Základy účetnictví",
        "countOfCards":30,
        "deckName":"cardDeck1",
        "rubCard":"rubSide"
      },
      "pexeso2":{
        "name":"Finanční účetnictví",
        "countOfCards":30,
        "deckName":"cardDeck2",
        "rubCard":"rubSide"
      }
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
